<template>
  <div class="projetos" id="projetos">
    <h1>Projetos<span>.</span></h1>
    <p>Alguns projetos desenvolvidos</p>

    
      <div class="ContainerProjetos">
        <div v-for="item in Projetos" :key="item.id" class="ProjectCard">
          <img :src="item.img" alt="project image" class="ProjectImage" />
          <div class="CardContent">
            <a :href="item.link" target="_blank">
              <h3 class="ProjectTitle">
                {{ item.name }} <ion-icon name="link-outline"></ion-icon></h3
            ></a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'projetosVue',
    data(){
      return {
      enterative: "./images/enterative.png",
      armbd: "./images/armbd.png",
      deco: "./images/deco.png",
      brandlaunch: "./images/brandlaunch.png",
      gbmagazine: "./images/mockupgb.png",
      codecompass: "/images/codecompass.png",

      decoutillink: "https://need10.lojavirtualnuvem.com.br/",
      enterativelink: "https://www.enterative.com.br/comercial",
      armbdlink: "https://www.armbd.com.br/",
      gbmagazinelink: "https://www.gbmagazine.com.br/",
      brandlaunchlink: "https://brandlaunch.com.br/",
      codecompasslink: "https://codecompass.com.br/",

      Projetos: [
      {
          id: 0,
          name: "Virtual X",
          img: "./images/VirtualX.png",
          link: "https://virtualxma.com/",
        },
        {
          id: 1,
          name: "Houseco",
          img: "./images/Houseco.png",
          link: "https://house-co.vercel.app/",
        },
        {
          id: 2,
          name: "CodeCompass",
          img: "./images/code.png",
          link: "https://codecompass.com.br/",
        },

        {
          id: 3,
          name: "Nova Weeb",
          img: "./images/novaweeb.png",
          link: "https://www.novaweeb.com.br/",
        },
        {
          id: 4,
          name: "ARMBD",
          img: "./images/armbd.png",
          link: "https://www.armbd.com.br/",
        },
        {
          id: 5,
          name: "Packaging Consultants",
          img: "./images/packaging.png",
          link: "https://packagingc.com/",
        },

        {
          id: 6,
          name: "Enterative",
          img: "./images/enterative.png",
          link: "https://www.enterative.com.br/comercial",
        },
        {
          id: 7,
          name: "Need Weather",
          img: "./images/weather.png",
          link: "https://need-weather.vercel.app/",
        },
        {
          id: 8,
          name: "GBMagazine",
          img: "./images/gbmagazine.png",
          link: "https://gbmagazine.lojavirtualnuvem.com.br/",
        },

        {
          id: 9,
          name: "LongForm Ayrton Senna",
          img: "./images/ayrton.png",
          link: "https://longform-ayrton-senna.vercel.app/",
        },

        {
          id: 9,
          name: "Corretora Mab",
          img: "./images/MAB.png",
          link: "https://corretoramab.com.br/",
        },

        {
          id: 9,
          name: "Portfólio Gabriel",
          img: "./images/gb.png",
          link: "https://gabriel-oliveira.vercel.app/",
        },
      ],
    };
  
    }
}
</script>

<style scoped>
body {
  overflow-x: hidden !important;
}


span {
  color: #4453e1;
}


h1 {
  text-align: center;
  margin-top: 5% !important;
  font-weight: bold !important;
  color: #2c3e50;
}

h3 {
  color: #2c3e50;
  font-weight: bold;
  text-align: center;
  margin-left: 2%;
}

.ContainerProjetos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px;
}

.ProjectCard {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  width: 400px;
  object-fit: contain;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  bottom:red solid 1px !important;

}

.ProjectCard:hover {
  transform: scale(1.05);
}

.ProjectImage {
  width: 100%;
  height: auto; /* Mantém a proporção original da imagem */
  object-fit: cover;
}

.CardContent {
  padding: 15px;
}

.ProjectTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

* {
  text-decoration: none;
}

@media only screen and (max-width: 869px) {
  body {
    overflow-x: hidden !important;
  }

  h1 {
    text-align: center;
    margin-top: 5% !important;
    font-weight: bold;
  }

  .ContainerProjetos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
  }

  .ProjectCard {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
    height: 300px;
    width: 400px;
    object-fit: contain;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }

  .ProjectCard:hover {
    transform: scale(1.05);
  }

  .ProjectImage {
    width: 100%;
    object-fit: cover;
    height: 200px;
  }

  .CardContent {
    padding: 15px;
  }

  .ProjectTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
</style>